import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { env } from "../../environment/environment";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  API_URL = env.API_URL + "/users";
  PAYMENT_URL = env.API_URL + "/create-payment-intent";
  INVOICES_URL = env.API_URL + "/stripe/invoices";
  public userSubject = new BehaviorSubject<any>({});
  public user$ = this.userSubject.asObservable();

  constructor(private http: HttpClient) {}

  findMe(): Observable<any> {
    return this.http
      .get(`${this.API_URL}/me`)
      .pipe(tap((data) => this.userSubject.next(data)));
  }

  update(body: any) {
    return this.http
      .put(`${this.API_URL}/${body.id}`, body)
      .pipe(tap((data) => this.userSubject.next(data)));
  }
  createPaymentIntenet() {
    return this.http
      .post(`${this.PAYMENT_URL}`,{});
  }
  getInvoices() {
    return this.http
      .get(`${this.INVOICES_URL}`)
  }
}
